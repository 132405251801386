// CryptoJS
// import CryptoJS from 'crypto-js'

// 随机字符串
export const randomString = (key) => {
  const len = key || 8
  const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = $chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}
// 节流
export const throttle = (fn, wait) => {
  let pre = Date.now()
  return function () {
    const context = this
    const args = arguments
    const now = Date.now()
    if (now - pre >= wait) {
      fn.apply(context, args)
      pre = Date.now()
    }
  }
}
// 防抖
export const debounce = (fn, wait) => {
  let Time = null
  return function () {
    const context = this
    const args = arguments
    if (Time !== null) {
      clearTimeout(Time)
    }
    Time = setTimeout(() => {
      fn.apply(context, args)
      Time = null
    }, wait)
  }
}
// 下载
export const DownCsv = (res, name) => {
  if (res != null && ['text/plain'].includes(res.type)) {
    const blob = new Blob([res], { type: 'text/plain' })
    const date =
      new Date().getFullYear() + '' +
      (new Date().getMonth() + 1) + '' +
      new Date().getDate() + '' +
      new Date().getHours() + '' +
      new Date().getMinutes() + '' +
      new Date().getSeconds() + '' +
      new Date().getMilliseconds()
    const fileName = name + date + '.csv'
    if ('download' in document.createElement('a')) {
      // 非IE下载
      const elink = document.createElement('a')
      elink.download = fileName
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    } else {
      // IE10+下载
      navigator.msSaveBlob(blob, fileName)
    }
  }
}
// 深拷贝
export const deepClone = (target, hash = new WeakMap()) => {
  if (target === null) return target
  if (target instanceof Date) return new Date(target)
  if (target instanceof RegExp) return new RegExp(target)
  if (target instanceof HTMLElement) return target

  if (typeof target !== 'object') return target

  if (hash.get(target)) return hash.get(target)
  const cloneTarget = new target.constructor()
  hash.set(target, cloneTarget)

  Reflect.ownKeys(target).forEach(key => {
    cloneTarget[key] = deepClone(target[key], hash)
  })
  return cloneTarget
}
// 深拷贝合并
export const deepMerge = (obj1, obj2) => {
  const isPlain1 = isPlainObject(obj1)
  const isPlain2 = isPlainObject(obj2)
  if (!isPlain1 || !isPlain2) return shallowMerge(obj1, obj2)
  const keys = [
    ...Object.keys(obj2),
    ...Object.getOwnPropertySymbols(obj2)
  ]
  keys.forEach(function (key) {
    obj1[key] = deepMerge(obj1[key], obj2[key])
  })

  return obj1
}
// 对象浅合并
export const shallowMerge = (obj1, obj2) => {
  const isPlain1 = isPlainObject(obj1)
  const isPlain2 = isPlainObject(obj2)
  if (!isPlain1) return obj2
  if (!isPlain2) return obj1
  const keys = [
    ...Object.keys(obj2),
    ...Object.getOwnPropertySymbols(obj2)
  ]
  keys.forEach(function (key) {
    obj1[key] = obj2[key]
  })

  return obj1
}
// 检测是否是纯对象
export const isPlainObject = (obj) => {
  if (!obj || Object.prototype.toString.call(obj) !== '[object Object]') {
    return false
  }
  const proto = Object.getPrototypeOf(obj)
  if (!proto) return true
  const Ctor = Object.prototype.hasOwnProperty.call(proto, 'constructor') && proto.constructor
  return typeof Ctor === 'function' && Function.prototype.toString.call(Ctor) === Function.prototype.toString.call(Object)
}
// 拍平数组
export const getNodeChild = (val) => {
  const data = deepClone(val)
  const arr = []
  data.reduce((total, item) => {
    total.push(item)
    if (item.children && item.children.length) {
      total.push(...getNodeChild(item.children))
    }
    return total
  }, arr)
  return arr
}
// 更新树形数据
export const updateParentReset = (treeData, key, value, update) => {
  const fn = (treeData, key, value, update) => {
    if (!treeData.length) return key
    for (let index = 0; index < treeData.length; index++) {
      if (treeData[index][key] === value) {
        treeData[index] = update
        break
      }
      if (treeData[index].children) {
        if (treeData[index].children.length) {
          fn(treeData[index].children, key, value, update)
        }
      }
    }
  }
  fn(treeData, key, value, update)
  return treeData
}
// 更新
export const updateParent = (treeData, key, value, update) => {
  const fn = (treeData, key, value, update) => {
    if (!treeData.length) return key
    for (let index = 0; index < treeData.length; index++) {
      if (treeData[index][key] === value) {
        const res = treeData[index]
        res.children = update
        break
      }
      if (treeData[index].children) {
        if (treeData[index].children.length) {
          fn(treeData[index].children, key, value, update)
        }
      }
    }
  }
  fn(treeData, key, value, update)
  return treeData
}
// 数据分组
export const groupArr = (arr, parentId, childId, keyName) => {
  const temp = {}
  const tree = {}
  arr.forEach(item => {
    temp[item[childId]] = item
  })
  const tempKeys = Object.keys(temp)
  tempKeys.forEach(key => {
    const item = temp[key]
    const _itemPId = item[parentId]
    const parentItemByPid = temp[_itemPId]
    if (parentItemByPid) {
      if (!parentItemByPid[keyName]) {
        parentItemByPid[keyName] = []
      }
      parentItemByPid[keyName].push(item)
    } else {
      tree[item[childId]] = item
    }
  })

  return Object.keys(tree).map(key => tree[key])
}
// 获取url参数
export const getUrlParams = (name) => {
  const reg = new RegExp('(^|&)' + JSON.stringify(name) + '=([^&]*)(&|$)', 'i') // 定义正则表达式
  if (window.location.search) {
    const r = window.location.search.substr(1).match(reg)
    if (r != null) return unescape(r[2])
  }
  return null
}
/* export const getUrlParamsData = (name) => {
  const urlObj = new URL(window.location)
  const urlSearchParams = urlObj.searchParams
  const paramObj = Object.fromEntries(urlSearchParams)
  let obj = null
  if (paramObj && paramObj[name]) {
    const data = base64Decode(paramObj[name])
    obj = JSON.parse(data)
  }
  return obj
} */
// base64 加密
/* export const base64Encode = (val) => {
  if (val) {
    const str = CryptoJS.enc.Utf8.parse(val)
    const base64 = CryptoJS.enc.Base64.stringify(str)
    return base64
  } else {
    return null
  }
}
// base64 解密
export const base64Decode = (val) => {
  if (val) {
    const words = CryptoJS.enc.Base64.parse(val)
    const deBase64 = words.toString(CryptoJS.enc.Utf8)
    return deBase64
  } else {
    return null
  }
} */
// 修改图片地址
export const transImg = (data) => {
  let img = null
  if (data) {
    img = data.replace('-', 'x') + '?x-oss-process=image/resize,w_156,limit_1'
  }
  return img
}
// 改变body高度
export const hideBodyHight = (type) => {
  if (document.body && !type) {
    document.body.classList.remove('adm-overflow-hidden')
  } else {
    document.body.classList.add('adm-overflow-hidden')
  }
}
// 数据拼接
export const httpUtil = (data) => {
  let ret = '?'
  for (const [key, value] of Object.entries(data)) {
    ret += key + '=' + value + '&'
  }
  return ret.slice(0, -1)
}
