import { useState, useEffect } from "react";
import {
  Space,
  Table,
  Avatar,
  Button,
  Form,
  Modal,
  Popconfirm,
  Toast,
  Upload,
} from "@douyinfe/semi-ui";
import {
  addStaff,
  dleStaff,
  staffModify,
  staffStateModify,
  staffList,
} from "@/api/staff";
import { IconDownload, IconUpload } from "@douyinfe/semi-icons";
import * as dateFns from "date-fns";

// 页面样式
import styles from "./index.module.scss";

const style = {
  boxShadow: "var(--semi-shadow-elevated)",
  backgroundColor: "var(--semi-color-bg-2)",
  borderRadius: "4px",
  padding: "10px",
  marginRight: "20px",
};

const Index = () => {
  // const { Option } = Form.Select;
  const [getWelfareVisible, setWelfareVisible] = useState(false);
  const [getStaffList, setStaffList] = useState({}); //员工列表
  const [seffState, setSeffState] = useState(false); //true:修改，false:新增
  const initPage = {
    page: 1,
    size: 10,
  };
  const [getQueryStallData, setQueryStallData] = useState(initPage);
  const [getFromStallData, setFromStallData] = useState(null);
  const [getQuery, setQuery] = useState({});
  useEffect(() => {
    quertStaffList();
  }, []);
  const [getStallLoading, setStallLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);

  //员工列表
  const quertStaffList = async (page = 1, values = {}) => {
    setStallLoading(true);
    try {
      const { code, data } = await staffList({
        ...getQueryStallData,
        page,
        ...values,
      });
      if (code != 0) return;
      setStaffList(data);
    } catch (error) {
      console.log(error);
    } finally {
      setStallLoading(false);
    }
  };
  //员工列表分页

  const handlePageChange = (page) => {
    setQueryStallData({
      ...getQueryStallData,
      page,
    });
    quertStaffList(page, getQuery);
  };
  //新增加员工
  const showDialog = () => {
    setWelfareVisible(true);
    setSeffState(false);
  };
  //seffState?"修改员工":"新增员工"
  const handleOk = (values) => {
    values.birthday = dateFns.format(new Date(values.birthday), "yyyy-MM-dd");
    if (seffState) {
      //修改员工
      staffModify(values).then(({ code, data }) => {
        if (code === 0) {
          quertStaffList();
          setFromStallData(null);
          setWelfareVisible(false);
        }
      });
    } else {
      //添加
      addStaff(values).then(({ code, data }) => {
        if (code === 0) {
          quertStaffList();
          setFromStallData(null);
          setWelfareVisible(false);
        }
      });
    }
  };
  const handleCancel = (e) => {
    setWelfareVisible(false);
  };

  const staffColumns = [
    {
      title: "员工工号",
      width: 120,
      dataIndex: "jobNumber",
      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "员工姓名",
      dataIndex: "name",
      render: (text, record, index) => {
        return (
          <div>
            <Avatar
              size="small"
              color={record.avatarBg}
              style={{ marginRight: 4 }}
            >
              {typeof text === "string" && text.slice(0, 1)}
            </Avatar>
            {text}
          </div>
        );
      },
    },
    {
      title: " 员工手机号",
      dataIndex: "mobile",
      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "员工邮箱",
      width: 250,
      dataIndex: "email",
      render: (value) => {
        return value;
      },
    },
    {
      title: "员工生日",
      dataIndex: "birthday",
      render: (value) => {
        return dateFns.format(new Date(value), "yyyy-MM-dd");
      },
    },
    {
      title: "部门",
      dataIndex: "department",
      render: (value) => {
        return value;
      },
    },
    {
      title: "岗位",
      dataIndex: "position",
      render: (value) => {
        return value;
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      width: 60,
      render: (value) => {
        value = value === 1 ? "启用" : "禁用";
        return value;
      },
    },
    {
      title: "操作",
      dataIndex: "updateTime",
      width: 280,
      render: (text, record) => (
        <div>
          <Button
            theme="borderless"
            onClick={() => {
              console.log("record", record);
              setFromStallData(record);
              setSeffState(true);
              setWelfareVisible(true);
            }}
          >
            信息修改
          </Button>
          <Popconfirm
            title="提示"
            content="是否确定启用该条信息?"
            onConfirm={() => {
              staffStateModify({ id: record.id, status: 1 }).then(
                ({ code, data }) => {
                  if (code === 0) {
                    //状态(0:禁用,1:启用)
                    quertStaffList();
                  }
                }
              );
            }}
          >
            <Button theme="borderless">启用</Button>
          </Popconfirm>

          <Popconfirm
            title="提示"
            content="是否确定停用该条信息?"
            onConfirm={() => {
              staffStateModify({ id: record.id, status: 0 }).then(
                ({ code, data }) => {
                  if (code === 0) {
                    quertStaffList();
                  }
                }
              );
            }}
          >
            <Button theme="borderless">停用</Button>
          </Popconfirm>

          <Popconfirm
            title="提示"
            content="是否确定删除该条信息?"
            onConfirm={() => {
              dleStaff({ id: record.id }).then(({ code, data }) => {
                if (code === 0) {
                  quertStaffList();
                }
              });
            }}
          >
            <Button theme="borderless">删除</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  //查询
  const queryStall = (values) => {
    if (values?.birthday) {
      values.birthday = dateFns.format(new Date(values.birthday), "yyyy-MM-dd");
    }
    setQuery(values);
    quertStaffList(1, values);
  };
  //导入员工
  /* const importStall=()=>{
		 staffTemplateUpload({"id": 0,"status": 0}).then(({ code, data }) => {
		   if (code === 0) {
		 	quertStaffList()
		   }
		 })
	 } */
  const load = (url) => {
    // window.location.href = process.env?.REACT_APP_BASE_URL + url;
    window.location.href = window.location.origin + url;
  };
  //下载模板
  const templateDownload = () => {
    load("/cp/issuance/user/template/download");
  };
  const onSuccess = (res) => {
    if (res.code == 0) return Toast.success("上传成功");
    setErrorVisible(true);
  };
  //下载错误提示
  const onLoadErrorInfo = async () => {
    load("/cp/issuance/user/template/upload/errors");
    setErrorVisible(false);
  };

  return (
    <div>
      <div size="small" style={style}>
        <div className={styles["dh-main"]}>
          <Space style={{ padding: "10px 20px" }}>
            <Form
              labelCol={{ span: 2 }}
              labelPosition="left"
              labelAlign="right"
              layout="horizontal"
              onSubmit={(values) => queryStall(values)}
              onReset={() => {
                setQueryStallData(initPage);
                quertStaffList();
              }}
            >
              <Form.Input
                field="jobNumber"
                label="员工工号"
                style={{ width: 176 }}
              />
              <Form.Input
                field="name"
                label="员工姓名"
                style={{ width: 176 }}
              />
              <Form.Input
                field="mobile"
                label="员工手机号"
                style={{ width: 176 }}
              />
              <Form.Input
                field="email"
                label="员工邮箱"
                style={{ width: 176 }}
              />
              <br />
              <br />
              <Form.Input
                field="position"
                label={{
                  text: "岗位",
                }}
                style={{ width: 176, marginLeft: 28 }}
              />
              <Form.Input
                field="department"
                label={{
                  text: "部门",
                }}
                style={{ width: 176, marginLeft: 28 }}
              />
              <Form.Input
                field="birthday"
                label="员工生日"
                style={{ width: 176 }}
              />
              <Button
                className="search-btn"
                htmlType="submit"
                style={{ width: 70 }}
              >
                查询
              </Button>
              <Button
                className="reset-btn"
                htmlType="reset"
                style={{ marginLeft: 10, width: 86 }}
              >
                重置
              </Button>
            </Form>
          </Space>
        </div>
      </div>
      <div style={{ height: 6 }}></div>
      <div size="small" style={style}>
        <div className={styles["dh-main"]}>
          <Space>
            <div style={{ display: "flex" }}>
              <Button className="search-btn" onClick={showDialog}>
                新增员工
              </Button>
              <Upload
                action={
                  "/cp/issuance/user/template/upload"
                }
                field={""}
                name={"file"}
                mutiple={false} // 是否支持多文件上传
                //   headers={{ "Content-Type": "multipart/form-data" }}
                sizeType={["small", "medium", "large"]} // 文件大小类型
                accept=".xlsx, .xls" // 接受文件类型
                showUploadList={false}
                onSuccess={(responseBody) => onSuccess(responseBody)}
              >
                <Button className="reset-btn" icon={<IconUpload />}>
                  员工导入
                </Button>
              </Upload>

              <Button
                className="reset-btn"
                icon={<IconDownload />}
                onClick={templateDownload}
              >
                下载模版
              </Button>
            </div>
          </Space>

          <Table
            columns={staffColumns}
            dataSource={getStaffList?.records}
            scroll={{
              y: 530,
            }}
            pagination={{
              currentPage: getQueryStallData.page,
              pageSize: getQueryStallData.size,
              total: getStaffList?.total,
              onPageChange: handlePageChange,
            }}
            loading={getStallLoading}
          />
        </div>
      </div>
      <Modal
        title={seffState ? "修改员工" : "新增员工"}
        visible={getWelfareVisible}
        onCancel={handleCancel}
        centered
        footer={null}
      >
        <Form
          labelPosition="left"
          initValues={getFromStallData}
          onSubmit={(values) => handleOk(values)}
          style={{
            padding: "0px 10px 10px 10px",
            textAlign: "right",
          }}
        >
          <Form.Input
            field="jobNumber"
            label="工号"
            trigger="blur"
            style={{ width: 300 }}
            rules={[{ required: true, message: "工号不能为空" }]}
          />
          <Form.Input
            field="name"
            label="姓名"
            trigger="blur"
            style={{ width: 300 }}
            rules={[{ required: true, message: "姓名不能为空" }]}
          />
          <Form.Input
            field="mobile"
            label="手机号"
            trigger="blur"
            style={{ width: 300 }}
            rules={[{ required: true, message: "手机号不能为空" }]}
          />
          <Form.Input
            field="email"
            label="邮箱"
            trigger="blur"
            style={{ width: 300 }}
            rules={[{ required: true, message: "邮箱不能为空" }]}
          />
          <Form.DatePicker
            label="生日"
            field="birthday"
            trigger="blur"
            rules={[{ required: true, message: "生日不能为空" }]}
            style={{ width: 300 }}
          />
          <Form.Input
            field="department"
            label="部门"
            trigger="blur"
            style={{ width: 300 }}
            rules={[{ required: true, message: "部门不能为空" }]}
          />
          <Form.Input
            field="position"
            label="岗位"
            trigger="blur"
            style={{ width: 300 }}
            rules={[{ required: true, message: "岗位不能为空" }]}
          />
          <Button
            className="reset-btn"
            htmlType="button"
            onClick={handleCancel}
            style={{ marginLeft: 10, width: 86 }}
          >
            取消
          </Button>
          <Button
            className="search-btn"
            htmlType="submit"
            style={{ width: 70 }}
          >
            确定
          </Button>
        </Form>
      </Modal>
      <Modal
        title="提示"
        visible={errorVisible}
        onCancel={() => setErrorVisible(false)}
        footer={null}
      >
        <div className={styles["error-box"]}>
          <h3 className={styles["error-tit"]}>导入失败</h3>
          <p>上传表格数据有误，请点击下方按钮查看错误提醒</p>
          <Button
            theme="solid"
            type="secondary"
            style={{ margin: 20 }}
            onClick={() => onLoadErrorInfo()}
          >
            下载错误提示
          </Button>
        </div>
      </Modal>
    </div>
  );
};
//{ validator: (rule, value) => value === 'semi', message: '' }
export default Index;
