import { combineReducers, createStore } from "redux";
import common from "./reducers/common";
import withApp from "./reducers/with-app";
// const {globSync} = require('glob');

// const modulesFiles = globSync('./reducers/*.js', { ignore: 'node_modules/**' });
// import { lazy } from 'react';

// const modulesFiles = lazy(() => import('@/reducers/*.jsx'));

// const modulesFiles = []//import.meta.globEager('./reducers/*.jsx')
const modulesFiles = {
  common: {
    ...common,
  },
  withApp: {
    ...withApp,
  },
};

/* const modules = Object.keys(modulesFiles).reduce((modules, path) => {
  const moduleName = path.replace(/(\.\/reducers\/|\.js)/g, '')
  modules[moduleName] = modulesFiles[path]?.default
  return modules
}, {}) */

export default createStore(combineReducers(modulesFiles));
