const defaultState = {
  projectName: '福利云后台',
  useInfo: null,
  Menu: [
    {
      itemKey: 'auth',
      text: '权限管理',
      items: [
        {
          open: ['auth'],
          itemKey: 'menu',
          text: '导航菜单',
          path: '/auth/auth-menu'
        },
        {
          open: ['auth'],
          itemKey: 'role',
          text: '权限控制',
          path: '/auth/auth-role'
        },
        {
          open: ['auth'],
          itemKey: 'member',
          text: '账号管理',
          path: '/auth/auth-member'
        }
      ]
    }
  ]
}

const calculate = (state = defaultState, action) => {
  if (action.type === 'EDITNAME') {
    const newState = JSON.parse(JSON.stringify(state))
    newState.userName = action.value
    return newState
  }

  return state
}

export default calculate
