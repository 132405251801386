import { useNavigate } from "react-router-dom";
import { useEffect } from "react"; //useState
// import { useSelector } from 'react-redux'
import { Layout, Form, Button } from "@douyinfe/semi-ui";
import { IconUser, IconLock } from "@douyinfe/semi-icons";
import { login } from "@/api/user";
import Cookie from "react-cookies";
// 页面样式
import styles from "./index.module.scss";
import FlyIcon from "../../assets/img/fly-icon.png";

const Index = () => {
  const navigate = useNavigate();
  // const [getToken,setToken] = useState(null)

  useEffect(() => {
    // 更新文档的标题
    document.title = "福利云后台";
  });

  // 登录
  const handleSubmit = (e) => {
    login("/mall/backend/user/account/login", e).then(({ code, data }) => {
      if (code === 0) {
        Cookie.save("AUTHTOKEN", data);
        navigate("/dataMarket");
      }
    });
    // navigate('/login')
  };

  return (
    <Layout className={styles["components-layout"]}>
      <Layout.Content className={styles["semi-layout-content"]}>
        <div className={styles.title}>
          <img src={FlyIcon} alt="" />
        </div>
        <Form style={{ width: 400 }} onSubmit={handleSubmit}>
          <Form.Input
            className={styles["semi-input-wrapper"]}
            field="username"
            prefix={<IconUser />}
            noLabel="true"
            placeholder="账号"
            size="large"
            autoComplete="off"
            rules={[{ required: true, message: "请输入账号" }]}
          />
          <Form.Input
            className={styles["semi-input-wrapper"]}
            field="password"
            prefix={<IconLock />}
            noLabel="true"
            placeholder="密码"
            size="large"
            mode="password"
            autoComplete="off"
            rules={[{ required: true, message: "请输入密码" }]}
          />
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 15 }}
          >
            <Button
              block
              htmlType="submit"
              type="primary"
              theme="solid"
              size="default"
              style={{ width: 100, background: "#0F8BFF" }}
            >
              登录
            </Button>
          </div>
        </Form>
      </Layout.Content>
    </Layout>
  );
};

export default Index;
