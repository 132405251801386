import { useRoutes } from "react-router-dom";

import Login from "../views/login/index.jsx";
import Home from "../views/home/index.jsx";
// import Dashboard from '../pages/Dashboard/index.jsx'
import StaffManage from "../views/staffManage/index.jsx";
import DataMarket from "../views/dataMarket/index.jsx";
import GiftAuto from "../views/gift/giftAuto/index.jsx";
import GiftQuery from "../views/gift/giftQuery/index.jsx";

const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "/dataMarket",
        element: <DataMarket />,
        index: true,
      },
      {
        path: "/staffManage",
        element: <StaffManage />,
        index: true,
      },
      {
        path: "/gift",
        children: [
          {
            path: "/gift/giftAuto",
            element: <GiftAuto />,
            index: true,
          },
          {
            path: "/gift/giftQuery",
            element: <GiftQuery />,
            index: true,
          },
        ],
      },
      /* {
        path: '*',
        name: '404',
        element: <Empty />
      } */
    ],
  },
];

const Routes = () => {
  const routers = useRoutes(routes);
  return routers;
};

export default Routes;
