import { Avatar, Typography } from "@douyinfe/semi-ui";
import { IconStar } from "@douyinfe/semi-icons";
import * as echarts from "echarts";

const { Text } = Typography;
//兑换商品数据
export const dyGoodsOption = (data) => {
  return [
    {
      key: "总兑换数",
      value: (
        <div style={{ marginRight: "100px", paddingLeft: "14px" }}>
          {data?.totalQuantity}
          <i style={{ fontSize: "11px" }}></i>
        </div>
      ),
    },

    {
      key: "已兑换数量",
      value: (
        <div style={{ marginRight: "100px", paddingLeft: "14px" }}>
          {data?.usedQuantity}
          <i style={{ fontSize: "11px" }}></i>
        </div>
      ),
    },

    {
      key: "未发放数量",
      value: (
        <div style={{ marginRight: "100px", paddingLeft: "14px" }}>
          {data?.unissuedQuantity} <i style={{ fontSize: "11px" }}></i>
        </div>
      ),
    },
    {
      key: "已发放数量",
      value: (
        <div style={{ marginRight: "100px", paddingLeft: "14px" }}>
          {data?.issuedQuantity}
          <i style={{ fontSize: "11px" }}></i>
        </div>
      ),
    },
    {
      key: "已领取人数",
      value: (
        <div style={{ marginRight: "100px", paddingLeft: "14px" }}>
          {data?.recipientQuantity}
          <i style={{ fontSize: "11px" }}></i>
        </div>
      ),
    },
  ];
};
export const dyGoodsTowOption = (data) => {
  return [];
};

const colors = [
  "#A69AFA",
  "#89DCFC",
  "#7CACF9",
  "#F4D77A",
  "#F0B676",
  "#EB8D71",
  "#F4D3E4",
  "#E4C2C0",
  "#70789C",
  "#EDEFC8",
  "#BFA1CD",
  "#7A99C1",
  "#AB9ECB",
  "#96C6CE",
  "#7B98BF",
  "#9B7BAD",
  "#76BEE2",
  "#9D7DAF",
  "#F0F7F8",
  "#8A4F93",
  "#FFF9E8",
  "#C9E5F2",
  "#E6BDD8",
  "#F5EAE1",
  "#73B5B7",
  "#FFF8EC",
  "#FEF14B",
  "#E9EEB1",
  "#8AC5E2",
  "#C58BBD",
  "#FCEEEE",
  "#E6E7E2",
  "#ADD6E6",
  "#22527C",
  "#4C5B78",
  "#4D4F6E",
  "#666C8D",
  "#758CB3",
  "#2E649E",
  "#78B4D8",
  "#EAF1F0",
  "#FEF8E8",
  "#F0E8DE",
  "#FCF6EC",

  "#A69AFA",
  "#89DCFC",
  "#7CACF9",
  "#F4D779",
  "#F0B676",
  "#EB8D71",
  "#800000",
  "#008000",
  "#000080",
  "#808000",
  "#800080",
  "#008080",
  "#C0C0C0",
  "#808080",
  "#FF9999",
  "#99FF99",
  "#9999FF",
  "#FFFF99",
  "#FF99FF",
  "#99FFFF",
  "#CC0000",
  "#00CC00",
  "#0000CC",
  "#CCCC00",
  "#CC00CC",
  "#00CCCC",
  "#FFCCCC",
  "#CCFFCC",
  "#CCCCFF",
  "#FFCC99",
  "#CCFF99",
  "#99FFCC",
  "#FF99CC",
  "#CC99FF",
  "#99CCFF",
  "#996600",
  "#006699",
  "#669966",
  "#660066",
  "#666600",
  "#006666",
  "#660000",
  "#006600",
  "#000066",
  "#666666",
  "#FF6666",
  "#66FF66",
  "#6666FF",
  "#FFFF66",
  "#FF66FF",
  "#66FFFF",
  "#990000",
  "#009900",
  "#000099",
  "#996600",
  "#990066",
  "#009966",
  "#996666",
  "#669966",
  "#666699",
  "#FF6633",
  "#33FF33",
  "#3333FF",
  "#FFFF33",
  "#FF33FF",
  "#33FFFF",
  "#993300",
  "#003300",
  "#000033",
  "#993300",
  "#990033",
  "#003333",
  "#993333",
  "#333933",
  "#336633",
  "#FF3300",
  "#00FF33",
  "#3300FF",
  "#FFFF00",
  "#FF00FF",
  "#00FFFF",
  "#990099",
  "#003399",
  "#999966",
  "#FF0033",
  "#33FF00",
  "#0033FF",
  "#FFFF00",
  "#FF00FF",
  "#00FFFF",
  "#993366",
  "#003366",
  "#996633",
  "#FF0000",
  "#00FF00",
  "#0000FF",
  "#FFFF00",
  "#FF00FF",
  "#00FFFF",
  "#990066",
  "#006699",
  "#336633",
  "#FF3366",
  "#66FF33",
  "#3366FF",
  "#FFFF33",
  "#FF33FF",
  "#33FFFF",
  "#CC3366",
  "#3366CC",
  "#CCCC33",
  "#FF9966",
  "#66FF99",
  "#9966FF",
];
//图标初始值
export const getGoodsOption = (echarsData) => {
  /* [
	  { value: 1048, name: 'Search Engine', itemStyle: {color: '#c23531'}},
	  { value: 735, name: 'Direct', itemStyle: {color: '#314656'}},
	  { value: 580, name: 'Email' , itemStyle: {color: '#dd8668'}},
	  { value: 484, name: 'Union Ads' , itemStyle: {color: '#91c7ae'}},
	  { value: 300, name: 'Video Ads' , itemStyle: {color: '#6e7074'}}
	] */

  const dataNew = [];
  for (let index = 0; index < echarsData.length; index++) {
    let element = echarsData[index];
    if (index >= 100) {
      dataNew.push({ value: element.quantity, name: element.name });
    } else {
      dataNew.push({
        value: element.quantity,
        name: element.name,
        itemStyle: { color: colors[index] },
      });
    }
  }
  // name
  // :
  // "Iphone 14 Pro test1 name updated"
  // percent
  // :
  // "44.93"
  // quantity
  // :
  // 31

  return {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "2%",
      left: "left",
      show: false,
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        // radius: ["60%", "90%"],
        // avoidLabelOverlap: false,
        label: {
          show: false,
          // position: "center",
        },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 20,
        //     fontWeight: "bold",
        //   },
        // },
        labelLine: {
          show: false,
        },
        data: dataNew,
      },
    ],
  };
};

//兑换数据
export const redemptionColumns = () => {
  return [
    {
      title: "商品名称",
      dataIndex: "name",
      width: 400,
      render: (text, record, index) => {
        return (
          <div>
            <Avatar
              size="small"
              shape="square"
              style={{ marginRight: 12, background: colors[record.index] }}
            ></Avatar>
            <Text
              ellipsis={{
                showTooltip: {
                  opts: { content: text },
                },
              }}
            >
              {text}
            </Text>
          </div>
        );
      },
    },
    /* {
        title: '商品类型',
        dataIndex: 'size',
        render: text => `${text} KB`,
    }, */
    {
      title: "兑换数量",
      dataIndex: "quantity",
      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "兑换占比",
      dataIndex: "percent",
      render: (value) => {
        return value + "%";
      },
    },
  ];
};
//-----------电话统计----------------
export const getOptionPhone = (chaertData, titleData) => {
  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: titleData,
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "次数",
      },
    ],
    series: [
      {
        name: "Direct",
        type: "bar",
        barWidth: "40%",
        itemStyle: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1, // 0,0,1,0表示从左向右    0,0,0,1表示从右向左
            [
              { offset: 0, color: "#D8E3FC" },
              { offset: 1, color: "#1E7FFE" },
            ]
          ),
        },
        data: chaertData,
      },
    ],
  };
};
//------------------电话数据统计
export const columnsPhone = () => {
  return [
    {
      title: "咨询时间",
      dataIndex: "createdDate",
      width: 200,
      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "咨询内容",
      dataIndex: "question",
      render: (text, record, index) => {
        return (
          <div>
            <Text
              ellipsis={{
                showTooltip: {
                  opts: { content: text },
                },
              }}
            >
              {text}
            </Text>
          </div>
        );
      },
    },
    {
      title: " 解决方案",
      dataIndex: "solution",
      render: (text, record, index) => {
        return (
          <div>
            <Text
              ellipsis={{
                showTooltip: {
                  opts: { content: text },
                },
              }}
            >
              {text}
            </Text>
          </div>
        );
      },
    },
    {
      title: "处理结果",
      dataIndex: "result",
      width: 200,
      render: (value) => {
        // 0:待处理,1:处理中,2:已完成
        return (
          <div>
            {value === 0 && <label style={{ color: "red" }}>待处理</label>}
            {value === 1 && <label style={{ color: "blue" }}>处理中</label>}
            {value === 2 && "已完成"}
          </div>
        );
      },
    },
  ];
};

//------------------反馈数统计
export const columnsFeedback = () => {
  return [
    {
      title: "兑换人",
      dataIndex: "username",
      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "兑换反馈",
      width: 400,
      dataIndex: "content",
      render: (text, record, index) => {
        return (
          <div>
            <Text
              ellipsis={{
                showTooltip: {
                  opts: { content: text },
                },
              }}
            >
              {text}
            </Text>
          </div>
        );
      },
    },
    {
      title: " 兑换评分",
      width: 300,
      dataIndex: "score",
      render: (text, record, index) => {
        // {(value === 0)&&(<label style={{color:'red'}}>失败</label>)}
        return (
          <div>
            {
              <div>
                <IconStar
                  style={{ color: text >= 20 ? "#f2f22f" : "gray" }}
                  size="extra-large"
                />
                <IconStar
                  style={{ color: text >= 40 ? "#f2f22f" : "gray" }}
                  size="extra-large"
                />
                <IconStar
                  style={{ color: text >= 60 ? "#f2f22f" : "gray" }}
                  size="extra-large"
                />
                <IconStar
                  style={{ color: text >= 80 ? "#f2f22f" : "gray" }}
                  size="extra-large"
                />
                <IconStar
                  style={{ color: text >= 100 ? "#f2f22f" : "gray" }}
                  size="extra-large"
                />
              </div>
            }
            {/* {(text >= 20&&text < 40)&&(<div>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: 'gray' }} size="extra-large"/>
					  <IconStar style={{ color: 'gray' }} size="extra-large"/>
					  <IconStar style={{ color: 'gray' }} size="extra-large"/>
					  <IconStar style={{ color: 'gray' }} size="extra-large"/>
					  </div>)}
					  {(text >= 40&&text < 60)&&(<div>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: 'gray' }} size="extra-large"/>
					  <IconStar style={{ color: 'gray' }} size="extra-large"/>
					  <IconStar style={{ color: 'gray' }} size="extra-large"/>
					  </div>)}
					  {(text >= 60&&text < 80)&&(<div>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: 'gray' }} size="extra-large"/>
					  <IconStar style={{ color: 'gray' }} size="extra-large"/>
					  </div>)}
					  {(text >= 80&&text < 100)&&(<div>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: 'gray' }} size="extra-large"/>
					  </div>)}
					  {(text >= 100)&&(<div>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  <IconStar style={{ color: '#f2f22f' }} size="extra-large"/>
					  </div>)} */}
          </div>
        );
      },
    },
    {
      title: "兑换时间",
      dataIndex: "createdDate",
      render: (value) => {
        return value;
      },
    },
  ];
};
