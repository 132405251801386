const defaultState = {
  withApp: [
    {
      label: 'Yami',
      value: 2
    },
    {
      label: 'Yami极速版',
      value: 8
    },
    {
      label: '蓝颜',
      value: 7
    },
    {
      label: 'Yavo',
      value: 9
    },
    {
      label: '千寻',
      value: 10
    },
    {
      label: '排排',
      value: 100
    }
  ]
}

const calculate = (state = defaultState, action) => {
  return state
}
export default calculate