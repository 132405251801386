import { useMemo, useState, useEffect, useRef } from "react"; //useEffect, useState,
import {
  Space,
  Table,
  Avatar,
  ButtonGroup,
  Button,
  Form,
  Modal,
  RadioGroup,
  Radio,
  Toast,
  Popconfirm,
} from "@douyinfe/semi-ui";
import {
  initialData,
  sendRulesList,
  sendStateModify,
  staffList,
  sendRuleAdd,
  queryChannelData,
  sendRuleInfo,
  sendModify,
} from "@/api/staff";
import { inintData } from "@/api/dataMarket";
import { deepMerge } from "@/utils/helpClass";
import * as dateFns from "date-fns";
// 页面样式
import styles from "./index.module.scss";

const columnsStall = [
  {
    title: "员工工号",
    dataIndex: "jobNumber",
    render: (text, record, index) => {
      return <div>{text}</div>;
    },
  },
  {
    title: "员工姓名",
    dataIndex: "name",
    render: (text, record, index) => {
      return (
        <div>
          <Avatar
            size="small"
            color={record.avatarBg}
            style={{ marginRight: 4 }}
          >
            {typeof text === "string" && text.slice(0, 1)}
          </Avatar>
          {text}
        </div>
      );
    },
  },
  {
    title: " 员工手机号",
    dataIndex: "mobile",
    render: (text, record, index) => {
      return <div>{text}</div>;
    },
  },
  {
    title: "员工邮箱",
    dataIndex: "email",
    render: (value) => {
      return value;
    },
  },
  {
    title: "员工生日",
    dataIndex: "birthday",
    render: (value) => {
      return dateFns.format(new Date(value), "yyyy-MM-dd");
    },
  },
  {
    title: "部门",
    dataIndex: "department",
    render: (value) => {
      return value;
    },
  },
  {
    title: "岗位",
    dataIndex: "position",
    render: (value) => {
      return value;
    },
  },
  {
    title: "状态",
    dataIndex: "status",
    render: (value) => {
      value = value === 1 ? "启用" : "禁用";
      return value;
    },
  },
];

const loading = false;
const style = {
  boxShadow: "var(--semi-shadow-elevated)",
  backgroundColor: "var(--semi-color-bg-2)",
  borderRadius: "4px",
  padding: "10px",
  margin: "15px",
};

const Index = () => {
  // const { Option } = Form.Select;
  const [getSendGiftVisible, setSendGiftVisible] = useState(false);
  const [sendState, setSendState] = useState(false);
  //表单初始值
  const [getFromSendData, setFromSendData] = useState(null);

  const [getPreviewVisible, setPreviewVisible] = useState(false);
  const [getChooseWelfareVisible, setChooseWelfareVisible] = useState(false);
  //发放列表
  const [getSendGiftList, setSendGiftList] = useState({});
  //mode模版
  const [getTemplate, setTemplate] = useState({});
  //渠道
  const [getchannelState, setchannelState] = useState(1);
  //发放类型
  const [getFestival, setFestival] = useState([]);

  //初始数据，合同ID
  const [getHtlist, setHtlist] = useState([]);
  const [getQueryStallData, setQueryStallData] = useState({
    page: 1,
    size: 10,
  });
  const [getSendPage, setSendPage] = useState({
    page: 1,
    size: 10,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [getStaffList, setStaffList] = useState(null); //员工列表
  const [sendType, setSendType] = useState(0);
  const [ruleId, setRuleId] = useState("");
  const [festivalId, setFestivalId] = useState(false);
  const [getQuery, setQuery] = useState({});
  const api = useRef();
  const setStallName = (list) => `已选择了${list.length}位员工`;
  useEffect(() => {
    initData();
    quertInitHtData();
    quertSendData();
    queryTemplate(getchannelState);
  }, []);

  //合同类型
  const quertInitHtData = () => {
    inintData({}).then(({ code, data }) => {
      if (code === 0) {
        const list = data.contracts.map((item) => ({
          value: item.id,
          label: item.name,
          otherKey: item.id,
        }));
        setHtlist(list);
      }
    });
  };
  //发放类型
  const initData = () => {
    initialData({}).then(({ code, data }) => {
      if (code === 0) {
        const list = data.festivals.map((item, index) => ({
          value: item.value,
          label: item.name,
          otherKey: item.value,
        }));
        setFestival(list);
      }
    });
  };
  //礼包列表接口
  const quertSendData = (page = 1) => {
    sendRulesList({ page, size: getSendPage.size }).then(({ code, data }) => {
      if (code === 0) {
        setSendGiftList(data);
      }
    });
  };
  //模版ID
  const queryTemplate = (mode) => {
    queryChannelData({ mode: mode }).then(({ code, data }) => {
      if (code === 0) {
        setTemplate(data);
      }
    });
  };
  //员工列表
  const quertStaffList = (values) => {
    staffList({ ...getQueryStallData, ...values }).then(({ code, data }) => {
      if (code === 0) {
        setStaffList(data);
      }
    });
  };
  //查询
  const queryStall = (values) => {
    if (values.birthday) {
      values.birthday = dateFns.format(new Date(values.birthday), "yyyy-MM-dd");
    }
    getQueryStallData.page = 1;
    setQuery(values);
    quertStaffList(values);
  };
  //编辑按钮
  const handleEdit = ({ id }) => {
    setRuleId(id);
    getRuleInfo({
      id,
    });
  };
  //根据id获取详情
  const getRuleInfo = async (params) => {
    const { code, data } = await sendRuleInfo(params);
    if (code != 0) return;
    const { sendingObjects, sendType, mode, festival } = data;
    setSendType(sendType);
    setchannelState(mode);
    setFestivalId(festival);
    if (sendType == 1) {
      setSelectedRowKeys(sendingObjects);
    }
    setFromSendData({ ...data, stallName: setStallName(sendingObjects) });
    setSendState(true);
    setSendGiftVisible(true);
  };
  //用户列表分页
  const userChange = (page) => {
    getQueryStallData.page = page;
    quertStaffList(getQuery);
  };
  //---

  const handlePageChange = (page) => {
    setSendPage.page = page;
    quertSendData(page);
  };

  const sendGiftDialog = () => {
    setSendState(false);
    setSendGiftVisible(true);
  };
  //---------选择员工
  const handleChooseWelfareDialog = () => {
    setChooseWelfareVisible(true);
    quertStaffList(); //员工列表
  };

  //------确定保存
  const handleSendGiftSave = async (values) => {
    if (values.sendTime) {
      values.sendTime = dateFns.format(
        new Date(values.sendTime),
        "yyyy-MM-dd hh:mm:ss"
      );
    }
    //保存
    const items = {
      contractId: values.contractId,
      festival: festivalId,
      mode: getchannelState,
      name: values.name,
      sendTime: values.sendTime,
      sendingObjects: selectedRowKeys, //values.stallName
      status: 0, //0:禁用
      subject: values.subject,
      templateId: getTemplate.id,
      sendType: sendType,
    };
    const { code } = sendState
      ? await sendModify({
          ...items,
          id: ruleId,
        })
      : await sendRuleAdd(items);
    if (code === 0) {
      quertSendData();
      setSendGiftVisible(false);
    }
  };
  //----取消发放礼物
  const handleSendGiftCancel = (e) => {
    setFromSendData(null);
    setSendGiftVisible(false);
  };
  //关闭预览
  const handlePreviewCancel = (e) => {
    setPreviewVisible(false);
  };
  //---预览
  const handlePreviewGift = (e) => {
    setPreviewVisible(true);
  };
  //确定选择员工
  const handleChooseWelfareOk = (e) => {
    if (!selectedRowKeys.length) return Toast.error("请选择员工");
    api.current.setValue("stallName", setStallName(selectedRowKeys));

    setChooseWelfareVisible(false);
  };
  //取消或关闭选择员工
  const handleChooseWelfareCancel = (e) => {
    setChooseWelfareVisible(false);
  };

  const rowSelection = useMemo(() => ({
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys,
  }));
  const scroll = useMemo(() => ({ y: 500 }), []);
  const scrollStall = useMemo(() => ({ y: 400 }), []);
  const columnsSendGift = [
    {
      title: "发放名称",
      dataIndex: "name",
      width: 200,
      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },
    {
      title: " 合同名称",
      dataIndex: "contractId",
      render: (text, record, index) => {
        return <div>{getHtlist.find((item) => item.value == text)?.label}</div>;
      },
    },
    {
      title: "发送类型",
      dataIndex: "festival",
      render: (text, record, index) => {
        return (
          <div>{getFestival.find((item) => item.value == text)?.label}</div>
        );
      },
    },
    {
      title: "发送渠道",
      dataIndex: "mode",
      render: (value) => {
        //发放方式(0:短信,1:邮件)
        value = value == 0 ? "短信" : "邮件";
        return value;
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (value) => {
        //状态(0:禁用,1:启用)
        return (
          <div>
            {value === 0 && <label style={{ color: "red" }}>禁用</label>}
            {value === 1 && "启用"}
          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "auto",
      render: (text, record) => (
        <div>
          <Button
            theme="borderless"
            onClick={() => {
              handleEdit(record);
            }}
          >
            编辑
          </Button>
          <Popconfirm
            title="提示"
            content="是否确定启用该条信息?"
            onConfirm={() => {
              sendStateModify({ id: record.id, status: 1 }).then(
                ({ code, data }) => {
                  if (code === 0) {
                    //状态(0:禁用,1:启用)
                    quertSendData();
                  }
                }
              );
            }}
          >
            <Button theme="borderless">启用</Button>
          </Popconfirm>

          <Popconfirm
            title="提示"
            content="是否确定停用该条信息?"
            onConfirm={() => {
              sendStateModify({ id: record.id, status: 0 }).then(
                ({ code, data }) => {
                  if (code === 0) {
                    quertSendData();
                  }
                }
              );
            }}
          >
            <Button theme="borderless">停用</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div size="small" style={style}>
        <Space>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
              <Button
                className="search-btn"
                style={{ marginRight: 10 }}
                onClick={sendGiftDialog}
              >
                新增发放礼包
              </Button>
            </div>
          </div>
        </Space>
        <Table
          columns={columnsSendGift}
          dataSource={getSendGiftList?.records}
          scroll={scroll}
          pagination={{
            currentPage: getSendPage.page,
            pageSize: getSendPage.size,
            total: getSendGiftList?.total,
            onPageChange: handlePageChange,
          }}
          loading={loading}
        />
      </div>
      <Modal
        title="选择员工"
        visible={getChooseWelfareVisible}
        onOk={handleChooseWelfareOk}
        onCancel={handleChooseWelfareCancel}
        centered
        bodyStyle={{ height: 700 }}
        width="80%"
      >
        <div size="small" style={style}>
          <div className={styles["dh-main-table"]}>
            <Space style={{ padding: "10px 20px" }}>
              <Form
                labelCol={{ span: 2 }}
                labelPosition="left"
                labelAlign="right"
                layout="horizontal"
                onSubmit={(values) => queryStall(values)}
              >
                <Form.Input
                  field="jobNumber"
                  label="员工工号"
                  style={{ width: 176 }}
                />
                <Form.Input
                  field="name"
                  label="员工姓名"
                  style={{ width: 176 }}
                />
                <Form.Input
                  field="mobile"
                  label="员工手机号"
                  style={{ width: 176 }}
                />
                <Form.Input
                  field="email"
                  label="员工邮箱"
                  style={{ width: 176 }}
                />
                <br />
                <br />
                <Form.Input
                  field="position"
                  label={{
                    text: "岗位",
                  }}
                  style={{ width: 176, marginLeft: 28 }}
                />
                <Form.Input
                  field="department"
                  label={{
                    text: "部门",
                  }}
                  style={{ width: 176, marginLeft: 28 }}
                />
                <Form.Input
                  field="birthday"
                  label="员工生日"
                  style={{ width: 176 }}
                />
                <Button
                  className="search-btn"
                  htmlType="submit"
                  style={{ width: 70 }}
                >
                  查询
                </Button>
                <Button
                  className="reset-btn"
                  htmlType="reset"
                  style={{ marginLeft: 10, width: 86 }}
                >
                  重置
                </Button>
              </Form>
            </Space>
          </div>
        </div>
        <div style={{ height: 6 }}></div>
        <div size="small" style={style}>
          <Table
            columns={columnsStall}
            dataSource={getStaffList?.records}
            rowSelection={rowSelection}
            rowKey="id"
            scroll={scrollStall}
            pagination={{
              currentPage: getQueryStallData.page,
              pageSize: getQueryStallData.size,
              total: getStaffList?.total,
              onPageChange: userChange,
            }}
            loading={loading}
          />
        </div>
      </Modal>
      <Modal
        title={sendState ? "修改发放礼包" : "新增发放礼包"}
        visible={getSendGiftVisible}
        onCancel={handleSendGiftCancel}
        centered
        footer={null}
      >
        <div style={{ marginTop: "-30px" }}>
          <div>
            <Form
              labelWidth="150"
              labelPosition="left"
              labelAlign="right"
              initValues={getFromSendData}
              onSubmit={(values) => handleSendGiftSave(values)}
              getFormApi={(formApi) => (api.current = formApi)}
              style={{
                padding: " 10px",
                textAlign: "right",
              }}
            >
              <Form.Input
                field="name"
                label="发放名称"
                maxLength="20"
                trigger="blur"
                style={{ width: 290 }}
                rules={[{ required: true, message: "请输入发放名称" }]}
              />
              <Form.Label label="全部员工" style={{ width: "100%" }}>
                <div className={styles["com-label"]}>
                  全部员工
                  <RadioGroup
                    field="sendType"
                    onChange={({ target }) => {
                      const { value } = target;
                      setSendType(value);
                      setSelectedRowKeys(value == 0 ? [-1] : []);
                      api.current.setValue("stallName", "");
                    }}
                    value={sendType}
                  >
                    <Radio value={0}>全部员工</Radio>
                    <Radio value={1}>部分员工</Radio>
                  </RadioGroup>
                </div>
              </Form.Label>
              {sendType == 1 && (
                <Form.Input
                  field="stallName"
                  label="发放对象"
                  readonly
                  trigger="custom"
                  placeholder="选择员工"
                  style={{ border: "1px solid cadetblue", width: 290 }}
                  onClick={handleChooseWelfareDialog}
                  rules={[{ required: true, message: "请选择员工" }]}
                />
              )}
              <Form.Select
                field="contractId"
                label={{ text: "选择合同" }}
                optionList={getHtlist}
                style={{ width: 290 }}
                rules={[
                  {
                    required: true,
                    message: "选择合同！",
                  },
                ]}
              ></Form.Select>
              <Form.Select
                field="festival"
                label={{ text: "发放类型" }}
                optionList={getFestival}
                style={{ width: 290 }}
                rules={[
                  {
                    required: true,
                    message: "请选择发放类型",
                  },
                ]}
                onChange={(value) => {
                  api.current.setValue("festival", value);
                  setFestivalId(value);
                }}
                value={festivalId}
              ></Form.Select>
              {festivalId != "Birthday" && (
                <Form.DatePicker
                  label="发送时间"
                  field="sendTime"
                  trigger="blur"
                  type="dateTime"
                  rules={[{ required: true, message: "请选择发送时间" }]}
                  style={{ width: 290 }}
                />
              )}

              <Form.Label label="发送渠道" style={{ width: "100%" }}>
                <div className={styles["com-label"]}>
                  发送渠道
                  <RadioGroup
                    field="channel"
                    aria-label="发送渠道"
                    label="发送渠道"
                    onChange={(e) => {
                      if (e.target.value == 1) {
                        setchannelState(1);
                        queryTemplate(1);
                      } else {
                        setchannelState(0);
                        queryTemplate(0);
                      }
                    }}
                    value={getchannelState}
                  >
                    <Radio value={1}>邮箱</Radio>
                    <Radio value={0}>手机短信</Radio>
                  </RadioGroup>
                </div>
              </Form.Label>
              <div style={{ opacity: getchannelState == 1 ? 1 : 0 }}>
                <Form.Input
                  field="subject"
                  label="邮件主题"
                  trigger="blur"
                  style={{ width: 290 }}
                  rules={[
                    {
                      required: getchannelState == 1,
                      message: "输入邮件主题",
                    },
                  ]}
                />
              </div>

              <br />
              <br />
              <Button
                className="reset-btn"
                onClick={handleSendGiftCancel}
                style={{ marginLeft: 15, width: 100, border: "1px solid gray" }}
              >
                取消
              </Button>
              <Button
                theme="borderless"
                type="secondary"
                onClick={handlePreviewGift}
                style={{
                  marginLeft: 15,
                  border: "1px solid #0095ee",
                }}
              >
                预览
              </Button>
              <Button
                className="search-btn"
                htmlType="submit"
                style={{ marginLeft: 15, width: 100 }}
              >
                确定
              </Button>
            </Form>
          </div>
        </div>
      </Modal>
      <Modal
        title="预览"
        visible={getPreviewVisible}
        onCancel={handlePreviewCancel}
        centered
        width={getchannelState == 1 ? 980 : 400}
        footer={null}
      >
        <div
          size="small"
          style={{ ...style, textAlign: "left" }}
          dangerouslySetInnerHTML={{ __html: getTemplate?.content }}
        ></div>
      </Modal>
    </div>
  );
};
export default Index;
