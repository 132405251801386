import { useState, useEffect } from "react";
import {
  Space,
  Table,
  ButtonGroup,
  Button,
  Form,
  Toast,
} from "@douyinfe/semi-ui";
// 页面样式
import styles from "./index.module.scss";
import { reSend, sendRecords } from "@/api/staff";
import * as dateFns from "date-fns";

const style = {
  boxShadow: "var(--semi-shadow-elevated)",
  backgroundColor: "var(--semi-color-bg-2)",
  borderRadius: "4px",
  padding: "10px",
  marginRight: "20px",
};
const initPage = {
  page: 1,
  size: 10,
};
const Index = () => {
  const [getQuery, setQuery] = useState({}); //表单查询参数
  const [getGiftList, setGiftList] = useState({});
  const [getTabState, setTabState] = useState(0);
  const [pageInfo, setPageInfo] = useState(initPage);

  const [getStallLoading, setStallLoading] = useState(false);
  useEffect(() => {
    queryGiftList(1);
  }, []);
  //员工列表分页
  const handlePageChange = (page) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
    queryGiftList(page, getQuery);
  };
  //查询
  const queryGift = (values) => {
    setQuery(values);
    queryGiftList(1, values);
  };
  const queryGiftByState = (state) => {
    queryGiftList(1, {
      ...getQuery,
      ...state,
    });
  };
  //员工列表
  const queryGiftList = async (page, values = {}) => {
    setStallLoading(true);
    try {
      const { code, data } = await sendRecords({
        ...values,
        ...pageInfo,
        page,
      });
      if (code != 0) return;
      setGiftList(data);
    } catch (error) {
      console.log(error);
    } finally {
      setStallLoading(false);
    }
  };

  const columns = [
    {
      title: "工号",
      dataIndex: "jobNumber",
      width: 100,
      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "姓名",
      width: 100,
      dataIndex: "name",
      render: (text) => `${text}`,
    },
    {
      title: " 手机号",
      dataIndex: "mobile",
      width: 100,
      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "员工邮箱",
      dataIndex: "email",
      render: (value) => {
        return value;
      },
    },
    {
      title: "员工生日",
      dataIndex: "birthday",
      render: (value) => {
        return value;
      },
    },
    {
      title: "部门",
      dataIndex: "department",
      render: (value) => {
        return value;
      },
    },
    {
      title: "岗位",
      dataIndex: "position",
      render: (value) => {
        return value;
      },
    },
    {
      title: "发放卡号",
      dataIndex: "cardNo",
      render: (value) => {
        return value;
      },
    },
    {
      title: "发送时间",
      dataIndex: "createdDate",
      render: (value) => {
        return dateFns.format(new Date(value), "yyyy-MM-dd");
      },
    },
    {
      title: "发放状态",
      dataIndex: "status",
      render: (value) => {
        //发放状态(0:失败, 1:成功)
        return (
          <div>
            {value === 0 && <label style={{ color: "red" }}>失败</label>}
            {value === 1 && "成功"}
          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "auto",
      render: (text, record) => (
        <div>
          <Button
            theme="borderless"
            onClick={() => {
              reSend({ id: record.id }).then(({ code, data }) => {
                if (code === 0) {
                  Toast.success("重发成功");
                  queryGiftList(1);
                }
              });
            }}
          >
            重发
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div size="small" style={style}>
        <div className={styles["dh-main"]}>
          <Space style={{ padding: "10px 20px" }}>
            <Form
              labelCol={{ span: 2 }}
              labelPosition="left"
              labelAlign="right"
              layout="horizontal"
              onSubmit={(values) => queryGift(values)}
              onReset={() => {
                setPageInfo(initPage);
                queryGiftList(1);
              }}
            >
              <Form.Input
                field="jobNumber"
                label="员工工号"
                style={{ width: 150 }}
              />
              <Form.Input
                field="name"
                label="员工姓名"
                style={{ width: 150 }}
              />
              <Form.Input
                field="mobile"
                label="员工手机号"
                style={{ width: 150 }}
              />
              <Form.Input
                field="email"
                label="员工邮箱"
                style={{ width: 150 }}
              />
              <br />
              <br />
              <Form.Input
                field="position"
                label={{
                  text: "岗位",
                }}
                style={{ width: 150 }}
              />
              <Form.Input
                field="department"
                label={{
                  text: "部门",
                }}
                style={{ width: 150 }}
              />
              <Form.Input
                field="birthday"
                label="员工生日"
                style={{ width: 150 }}
              />
              <Form.Input
                field="cardNo"
                label="发放卡号"
                style={{ width: 150 }}
              />
              <Button
                htmlType="submit"
                className="search-btn"
                style={{ width: 70 }}
              >
                查询
              </Button>
              <Button
                htmlType="reset"
                className="reset-btn"
                style={{ marginLeft: 10, width: 86 }}
              >
                重置
              </Button>
            </Form>
          </Space>
        </div>
      </div>
      <div style={{ height: 6 }}></div>
      <div size="small" style={style}>
        <div className={styles["dh-main"]}>
          <Space>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: 10 }}>
                <ButtonGroup>
                  <Button
                    theme="solid"
                    type={getTabState === 0 ? "primary" : "tertiary"}
                    onClick={(values) => {
                      queryGiftByState({ status: null });
                      setTabState(0);
                    }}
                  >
                    全部
                  </Button>
                  <Button
                    theme="solid"
                    type={getTabState === 1 ? "primary" : "tertiary"}
                    onClick={(values) => {
                      queryGiftByState({ status: 0 });
                      setTabState(1);
                    }}
                  >
                    失败
                  </Button>
                  <Button
                    theme="solid"
                    type={getTabState === 2 ? "primary" : "tertiary"}
                    onClick={(values) => {
                      queryGiftByState({ status: 1 });
                      setTabState(2);
                    }}
                  >
                    成功
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </Space>
          <Table
            columns={columns}
            dataSource={getGiftList?.records}
            pagination={{
              currentPage: pageInfo.page,
              pageSize: pageInfo.size,
              total: getGiftList?.total,
              onPageChange: handlePageChange,
            }}
            loading={getStallLoading}
          />
        </div>
      </div>
    </div>
  );
};
export default Index;
