import http from '@/service/requert'

//兑换数据
export const redemptionData = (data) => {
  return http.post('/cp/dashboard/overview/statistics',data)
}
//兑换商品
export const redemptionGoods = (data) => {
  return http.post('/cp/dashboard/overview/analysis',data)
}
//400电话咨询
export const bTelephone = (data) => {
  return http.post('/cp/dashboard/400/content',data)
}
//400电话图表
export const bTelephoneEchar = (data) => {
  return http.post('/cp/dashboard/400/statistics',data)
}
//兑换意见反馈
export const feedback = (data) => {
  return http.post('/cp/dashboard/reviews',data)
}
//初始数据
export const inintData = (data) => {
  return http.post('/cp/commons/contracts',data)
}
