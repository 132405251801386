import { useEffect, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux"; //useDispatch,
import {
  Layout,
  Nav,
  Space,
  Tooltip,
  Dropdown,
  Button,
  Avatar,
} from "@douyinfe/semi-ui"; //Toast
import {
  IconSun,
  IconMoon,
  IconUser,
  IconStar,
  IconSetting,
  IconSemiLogo,
} from "@douyinfe/semi-icons"; //IconSend
import Cookie from "react-cookies";
// 页面样式
import styles from "./index.module.scss";
// 接口
import { exitLogin } from "@/api/user";
// import Http from 'service/api'
// import logo from '@/assets/img/logo.png'

// 拍平树形结构
/* const getNodeChild = (data) => {
  const arr = data?.reduce((total, item) => {
    total.push(item)
    if (item.items && item.items.length) {
      total.push(...getNodeChild(item.items))
    }
    return total
  }, [])
  return arr
} */

const Index = () => {
  const common = useSelector((state) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const [isSun, setIsSun] = useState(false);
  const [menuItems] = useState([]); //setMenuItems
  const [user] = useState(null); //setUser
  const [selectedKeys, setSelectedKeys] = useState(["dataMarket"]); //setUser

  // 权限控制
  /* useEffect(() => {
    document.title = '福利云后台'

    let authToken = Cookie.load('AUTHTOKEN')
    if (authToken) {
      // getUse()
    } else {
      const names = Object.keys(Cookie.loadAll())
      names.forEach(element => {
        Cookie.remove(element)
      })
      sessionStorage.clear()
      navigate('/login')
    }
    // 页面卸载
    return () => {
      authToken = null
    }
  }, []) */

  useEffect(() => {
    let authToken = Cookie.load("AUTHTOKEN");
    if (authToken) {
    } else {
      const names = Object.keys(Cookie.loadAll());
      names.forEach((element) => {
        Cookie.remove(element);
      });
      sessionStorage.clear();
      navigate("/login");
    }
    // 页面卸载
    return () => {
      authToken = null;
    };
  }, [menuItems, location]);

  // 导航选中
  const onSelect = (data) => {
    data.selectedItems.forEach(function (item) {
      if (item && item?.path) {
        item?.path && navigate(item.path);
      }
    });
    setSelectedKeys(data.selectedKeys);
  };

  // 退出
  const onEdit = () => {
    exitLogin("/mall/backend/user/exit", {}).then(({ code, data }) => {
      if (code === 0) {
        const names = Object.keys(Cookie.loadAll());
        names.forEach((element) => {
          Cookie.remove(element);
        });
        sessionStorage.clear();
        navigate("/login");
      }
    });
  };

  // 主题切换
  const switchMode = () => {
    const body = document.body;
    if (body.hasAttribute("theme-mode")) {
      body.removeAttribute("theme-mode");
      setIsSun(false);
    } else {
      body.setAttribute("theme-mode", "dark");
      setIsSun(true);
    }
  };

  // 用户导航
  const userItems = [{ node: "item", name: "退出", onClick: onEdit }];
  const imgSrc = require("../../assets/img/fly-icon.png");
  return (
    <div className={styles.layout}>
      <header>
        <Nav
          mode="horizontal"
          header={{
            logo: (
              <img
                src={imgSrc}
                style={{ width: 180, height: 50 }}
                onClick={() => navigate("/dataMarket")}
              ></img>
            ),
          }}
          footer={
            <Space spacing={[12]}>
              <Tooltip content={isSun ? "切换亮色主题" : "切换暗色主题"}>
                <Button
                  type="tertiary"
                  theme="borderless"
                  onClick={switchMode}
                  icon={
                    isSun ? <IconSun size="large" /> : <IconMoon size="large" />
                  }
                />
              </Tooltip>
              <Dropdown
                trigger={"click"}
                position={"bottomRight"}
                menu={userItems}
              >
                <Avatar
                  style={{ marginLeft: "8px" }}
                  size="medium"
                  alt={user?.avatar}
                  src="https://p9-passport.byteacctimg.com/img/mosaic-legacy/3797/2889309425~120x256.image"
                ></Avatar>
              </Dropdown>
            </Space>
          }
        ></Nav>
      </header>
      <main className={styles.container}>
        <Layout.Sider className={styles.sider}>
          <Nav
            selectedKeys={selectedKeys}
            bodyStyle={{ height: "calc(100vh - 140px)" }}
            items={[
              {
                itemKey: "dataMarket",
                path: "/dataMarket",
                text: "数据大盘",
                icon: <IconUser />,
              },
              {
                itemKey: "staffManage",
                path: "/staffManage",
                text: "员工管理",
                icon: <IconStar />,
              },
              {
                text: "礼包发放",
                icon: <IconSetting />,
                itemKey: "gift",
                items: [
                  {
                    itemKey: "auto",
                    text: "礼包设置",
                    path: "/gift/giftAuto",
                  },
                  {
                    itemKey: "query",
                    text: "发放查询",
                    path: "/gift/giftQuery",
                  },
                ],
              },
            ]}
            onSelect={onSelect}
            footer={{
              collapseButton: true,
            }}
          />
        </Layout.Sider>
        <div className={styles["right-layout"]}>
          <Outlet />
        </div>
      </main>
    </div>
  );
};
export default Index;
