// 页面样式
import styles from "./index.module.scss";
import React, { useEffect, useState, useMemo } from "react";
import {
  Descriptions,
  Select,
  Space,
  Table,
  DatePicker,
} from "@douyinfe/semi-ui";
import ReactEcharts from "echarts-for-react";
import {
  redemptionGoods,
  redemptionData,
  bTelephone,
  bTelephoneEchar,
  feedback,
  inintData,
} from "@/api/dataMarket";
import {
  redemptionColumns,
  getOptionPhone,
  columnsPhone,
  columnsFeedback,
  getGoodsOption,
  dyGoodsOption,
  dyGoodsTowOption,
} from "./columns";
import * as dateFns from "date-fns";
import { deepMerge } from "@/utils/helpClass";

const Index = () => {
  //兑换商品分析
  const [getChartGoodsData, setChartGoodsData] = useState([]);
  const [getTableGoodsData, setTableGoodsData] = useState([]);
  //兑换数据显示
  const [getdyData, setdyData] = useState(null);
  //400电话图表
  const [getPhoeEcharData, setPhoeEcharData] = useState(null); //[10, 52, 200, 334, 390, 330, 220],['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  //400电话Table
  const [getPhoeTableData, setPhoeTableData] = useState([]);
  //意见反馈
  const [getFeedbackData, setFeedbackData] = useState([]);
  //初始数据，合同ID
  const [getHtData, setHtData] = useState({});
  const [getHtlist, setHtlist] = useState([]);
  const [getTotal, setTotal] = useState(0);
  const defDate = [
    dateFns.format(dateFns.startOfMonth(new Date()), "yyyy-MM-dd"),
    dateFns.format(new Date(), "yyyy-MM-dd"),
  ];

  useEffect(() => {
    quertInitData();
    quertPhoeEcharData(defDate);
    quertPhoeTableData(defDate);
    quertFeedbackData();
  }, []);

  const selectInitHtValue = (e) => {
    setHtData({ id: e });
  };
  //初始数据
  const quertInitData = () => {
    inintData({}).then(({ code, data }) => {
      if (code === 0) {
        const dataNew = data.contracts.map((item) => ({
          value: item.id,
          label: item.name,
          otherKey: item.id,
        }));
        setHtlist([
          { value: "all", label: "全部合同", otherKey: "all" },
          ...dataNew,
        ]);
        setHtData({ id: "all" });
        //-----
      }
    });
  };
  useEffect(() => {
    if (getHtData.id) {
      quertDyData();
      quertGoodsFxData();
    }
  }, [getHtData.id]);
  //兑换数据显示
  const quertDyData = () => {
    redemptionData({
      contractId: getHtData?.id == "all" ? "" : getHtData?.id,
    }).then(({ code, data }) => {
      if (code === 0) {
        setdyData(data);
      }
    });
  };

  //商品分析
  const quertGoodsFxData = () => {
    redemptionGoods({
      contractId: getHtData?.id == "all" ? "" : getHtData?.id,
    }).then(({ code, data }) => {
      if (code === 0) {
        setChartGoodsData(data);
        data.sort((a, b) => {
          // 获取日期对象
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          // 比较日期对象
          return dateA - dateB;
        });
        let items = [];
        for (var index = 0; index < data.length; index++) {
          var element = data[index];
          items.push(deepMerge(element, { index: index }));
        }
        setTableGoodsData(items);
      }
    });
  };
  //400电话图标
  const quertPhoeEcharData = (date) => {
    bTelephoneEchar({
      startDate: date[0],
      endDate: date[1],
    }).then(({ code, data }) => {
      if (code === 0) {
        data.sort((a, b) => {
          // 获取日期对象
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          // 比较日期对象
          return dateA - dateB;
        });
        //[10, 52, 200, 334, 390, 330, 220],['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        let items1 = [];
        let items2 = [];
        for (var index = 0; index < data.length; index++) {
          var element = data[index];
          items1.push(element.date);
          items2.push(element.quantity);
        }
        let items = {
          date: items1,
          quantity: items2,
        };

        const total = data.reduce((pre, cur) => {
          pre += cur.quantity;
          return pre;
        }, 0);
        setTotal(total);
        setPhoeEcharData(items);
      }
    });
  };
  //400电话Table
  const quertPhoeTableData = (date) => {
    bTelephone({
      startDate: date[0],
      endDate: date[1],
    }).then(({ code, data }) => {
      if (code === 0) {
        setPhoeTableData(data?.records);
      }
    });
  };
  //意见反馈
  const quertFeedbackData = () => {
    let data = {
      /* 
				  "page": 0,
				  "size": 0,
				  "skuCode": "string",
				  "spuCode": "string",
				  "status": 0 */
    };
    feedback(data).then(({ code, data }) => {
      if (code === 0) {
        setFeedbackData(data?.records);
      }
    });
  };

  const onChartReadyCallback = (e) => {
    let option = e.getOption();
    let colors = [];
    option.series.forEach(function (serie) {
      if (serie.data) {
        serie.data.forEach(function (dataItem) {
          if (dataItem.itemStyle && dataItem.itemStyle.color) {
            colors.push(dataItem.itemStyle.color);
          }
        });
      }
    });

    // 现在 colors 数组中包含了图表中所有系列的颜色值
  };
  //兑换
  const [getBackPage, setBackPage] = useState(1);
  const [getBackLoading, setBackLoading] = useState(false);
  const handleBackPageChange = (page) => {
    setBackLoading(true);
    setBackPage(page);
    return new Promise((res, rej) => {
      setTimeout(() => {
        const data = getFeedbackData;
        let dataSource = data.slice((page - 1) * 5, page * 5);
        res(dataSource);
      }, 300);
    }).then((dataSource) => {
      setBackLoading(false);
    });
  };
  //400电话咨询一览
  const [getPhonePage, setPhonePage] = useState(1);
  const [getPhoneLoading, setPhoneLoading] = useState(false);
  const handlePagePhoneChange = (page) => {
    // fetchData(page);
    setPhoneLoading(true);
    setPhonePage(page);
    return new Promise((res, rej) => {
      setTimeout(() => {
        const data = getPhoeTableData;
        let dataSource = data.slice((page - 1) * 5, page * 5);
        res(dataSource);
      }, 300);
    }).then((dataSource) => {
      setPhoneLoading(false);
    });
  };
  //兑换
  const [getDhPage, setDhPage] = useState(1);
  const [getDhLoading, setDhLoading] = useState(false);
  const handleDyPageChange = (page) => {
    setDhLoading(true);
    setDhPage(page);
    return new Promise((res, rej) => {
      setTimeout(() => {
        const data = getTableGoodsData;
        let dataSource = data.slice((page - 1) * 5, page * 5);
        res(dataSource);
      }, 300);
    }).then((dataSource) => {
      setDhLoading(false);
    });
  };

  const styleInfn = {
    backgroundColor: "var(--semi-color-bg-2)",
    borderRadius: "4px",
    padding: "20px",
    marginLeft: "100px",
  };

  const style = {
    boxShadow: "var(--semi-shadow-elevated)",
    backgroundColor: "var(--semi-color-bg-2)",
    borderRadius: "4px",
    padding: "10px",
    marginRight: "20px",
  };
  const scroll = useMemo(() => ({ y: 300 }), []);

  return (
    <div>
      <div size="small" style={style}>
        <div className={styles["dh-main"]}>
          <div className={styles["tit-box"]}>
            <span className={styles["dh-title"]}>兑换数据概览</span>
            <div className={styles["tit-box"]}>
              <label>合同:</label>
              <Select
                field="role"
                value={getHtData?.id}
                placeholder="全部合同"
                optionList={getHtlist}
                onChange={selectInitHtValue}
                style={{ width: 200, marginLeft: "8px" }}
              ></Select>
            </div>
          </div>
        </div>
        <Descriptions
          row
          data={dyGoodsOption(getdyData)}
          style={styleInfn}
          size="large"
        />
      </div>
      <br />
      <div size="small" style={style}>
        <div className={styles["dh-main"]}>
          <Space style={{ width: "85%" }}>
            <span className={styles["dh-title"]}>兑换商品分析</span>
          </Space>
          <div className={styles["goods-type-data"]}>
            <div className={styles["echart-data"]}>
              <ReactEcharts
                option={getGoodsOption(getChartGoodsData)}
                lazyUpdate={true}
                onChartReady={onChartReadyCallback}
              />
            </div>
            <div className={styles["echart-data-table"]}>
              <Table
                columns={redemptionColumns()}
                dataSource={getTableGoodsData}
                pagination={{
                  getDhPage,
                  pageSize: 5,
                  total: getTableGoodsData?.length,
                  onPageChange: handleDyPageChange,
                }}
                scroll={scroll}
                loading={getDhLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div size="small" style={style}>
        <div className={styles["dh-main"]}>
          <div className={styles["tit-box"]}>
            <span className={styles["dh-title"]}>400电话咨询一览</span>
            <div className={styles["tit-box"]}>
              <label>日期:</label>
              <DatePicker
                type="dateRange"
                style={{ width: 260, marginLeft: 8 }}
                defaultValue={defDate}
                onChange={(e) => {
                  let time = [
                    dateFns.format(new Date(e[0]), "yyyy-MM-dd"),
                    dateFns.format(new Date(e[1]), "yyyy-MM-dd"),
                  ];
                  console.log("time==", e);
                  quertPhoeEcharData(time);
                  quertPhoeTableData(time);
                }}
              />
            </div>
          </div>
          <div style={{ fontSize: "13px", padding: "20px 20px" }}>
            进线次数:
            <label style={{ fontSize: "18px", fontWeight: "bold" }}>
              {getTotal}
            </label>
            次
          </div>
          <ReactEcharts
            option={getOptionPhone(
              getPhoeEcharData?.quantity,
              getPhoeEcharData?.date
            )}
            lazyUpdate={true}
            onChartReady={onChartReadyCallback}
          />
          <Table
            columns={columnsPhone()}
            dataSource={getPhoeTableData}
            pagination={{
              getPhonePage,
              pageSize: 5,
              total: getPhoeTableData?.length,
              onPageChange: handlePagePhoneChange,
            }}
            scroll={scroll}
            loading={getPhoneLoading}
          />
        </div>
      </div>
      <br />
      <div size="small" style={style}>
        <div className={styles["dh-main"]}>
          <Space style={{ width: "85%" }}>
            <span className={styles["dh-title"]}>兑换意见反馈</span>
          </Space>
          <Table
            columns={columnsFeedback()}
            dataSource={getFeedbackData}
            pagination={{
              getBackPage,
              pageSize: 5,
              total: getFeedbackData?.length,
              onPageChange: handleBackPageChange,
            }}
            loading={getBackLoading}
          />
        </div>
      </div>
    </div>
  );
};
export default Index;
