import http from '@/service/requert'

//初始数据
export const initialData = (data) => {
  return http.post('/cp/issuance/rule/initialData',data)
}
//重新发送
export const reSend = (data) => {
  return http.post('/cp/issuance/record/retry',data)
}
//发放记录
export const sendRecords = (data) => {
  return http.post('/cp/issuance/records',data)
}
//发放规则添加
export const sendRuleAdd = (data) => {
  return http.post('/cp/issuance/rule/add',data)
}
//发放规则详情
export const sendRuleInfo = (data) => {
  return http.post('/cp/issuance/rule/details',data)
}
//查询发放渠道模版
export const queryChannelData = (data) => {
  return http.post('/cp/issuance/rule/mode/template',data)
}
//发放规则修改
export const sendModify = (data) => {
  return http.post('/cp/issuance/rule/modify',data)
}
//发放规则状态修改
export const sendStateModify = (data) => {
  return http.post('/cp/issuance/rule/status/modify',data)
}
//发放规则列表
export const sendRulesList = (data) => {
  return http.post('/cp/issuance/rules',data)
}
//员工添加
export const addStaff = (data) => {
  return http.post('/cp/issuance/user/add',data)
}
//删除员工
export const dleStaff = (data) => {
  return http.post('/cp/issuance/user/delete',data)
}
//员工详情
export const staffInfo = (data) => {
  return http.post('/cp/issuance/user/details',data)
}
//员工修改
export const staffModify = (data) => {
  return http.post('/cp/issuance/user/modify',data)
}
//员工状态修改
export const staffStateModify = (data) => {
  return http.post('/cp/issuance/user/status/modify',data)
}
//员工导入模版下载
export const staffTemplateDownload = (data) => {
  return http.get('/cp/issuance/user/template/download',data)
}
//员工模版上传
export const staffTemplateUpload = (data) => {
  return http.post('/cp/issuance/user/template/upload',data)
}
//员工模版上传错误文件
export const staffUploadErrFile = (data) => {
  return http.post('/cp/issuance/user/template/upload/errors',data)
}
//员工列表
export const staffList = (data) => {
  return http.post('/cp/issuance/users',data)
}