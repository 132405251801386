import axios from "axios";
import { Toast } from "@douyinfe/semi-ui";
import Cookie from "react-cookies";
// import Qs from 'qs'

const oAxios = axios.create({
  baseURL: "",
  headers: { "Content-Type": "application/json" },
});

// 请求拦截器
oAxios.interceptors.request.use(
  (config) => {
    const Authorization = Cookie.load("AUTHTOKEN");
    if (Authorization) {
      config.headers.Authorization = Authorization; // 登录token（后端登录接口返回）
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截器
oAxios.interceptors.response.use(
  (response) => {
    const { data } = response;
    if ([100100, 100301].includes(data.code)) {
      // 无效code,跳转至域名
      sessionStorage.clear();
      Cookie.remove("AUTHTOKEN");
      Toast.error({ content: data.message });
      setTimeout(() => {
        window.location.href =
          window.location.protocol + "//" + window.location.host + "/login";
      }, 1000);
    } else if ([0, 200].includes(data.code)) {
      // 成功
      return Promise.resolve(data);
    } else {
      Toast.error({ content: data.message || data.error });
      return Promise.resolve(data);
    }
  },
  (error) => Promise.reject(error)
);

class Http {
  axios({ method = "get", url, data, ...configs }) {
    return new Promise((resolve, reject) => {
      oAxios({
        url: url,
        method,
        data,
        ...configs,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  get(url, params) {
    return this.axios({
      method: "get",
      url,
      params,
    });
  }
  post(url, data, config) {
    return this.axios({
      method: "post",
      url,
      data,
      config,
    });
  }
  put(url, data) {
    return this.axios({
      method: "put",
      url,
      data,
    });
  }
  putImgData(url, config) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open("PUT", url);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("x-oss-meta-author", "xxxx");
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.responseURL) {
            resolve({
              data: xhr.responseURL,
            });
          } else {
            reject({
              msg: "put: error",
            });
          }
        }
      };
      xhr.send(this.dataURLtoBlob(config.body));
    });
  }

  dataURLtoBlob(dataurl) {
    if (dataurl.content) {
      dataurl = dataurl.content;
    }
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {
      type: mime,
    });
  }
}
export default new Http();
